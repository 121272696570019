import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import './Register.css';
import logo from '../../assets/logo.png';

const Register = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [ddd, setDdd] = useState('');
    const [contact, setContact] = useState('');
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch countries
        axios.get('https://restcountries.com/v3.1/all')
            .then(response => {
                const countryList = response.data.map(country => country.name.common);
                setCountries(countryList);
            })
            .catch(error => {
                console.error('Erro ao buscar países:', error);
            });
    }, []);

    useEffect(() => {
        if (country) {
            // Fetch states based on selected country
            axios.get(`http://api.geonames.org/searchJSON?country=${country}&featureCode=ADM1&username=demo`)
                .then(response => {
                    const stateList = response.data.geonames.map(state => state.name);
                    setStates(stateList);
                })
                .catch(error => {
                    console.error('Erro ao buscar estados:', error);
                });
        }
    }, [country]);

    useEffect(() => {
        if (state) {
            // Fetch cities based on selected state
            axios.get(`http://api.geonames.org/searchJSON?adminCode1=${state}&featureCode=PPLA&username=demo`)
                .then(response => {
                    const cityList = response.data.geonames.map(city => city.name);
                    setCities(cityList);
                })
                .catch(error => {
                    console.error('Erro ao buscar cidades:', error);
                });
        }
    }, [state]);

    const handleRegister = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert('As senhas não coincidem');
            return;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            alert('Por favor, insira um email válido');
            return;
        }
        try {
            const response = await axios.post('http://localhost:3001/register', {
                fullName,
                email,
                password,
                country,
                state,
                city,
                contact: `(${ddd}) ${contact}`
            });
            alert('Usuário registrado com sucesso!');
        } catch (error) {
            console.error('Erro ao registrar:', error);
            alert('Erro ao registrar');
        }
    };

    const handleBackToLogin = () => {
        navigate('/');
    };

    return (
        <div className="register-container">
            <div className="back-button" onClick={handleBackToLogin}>
                <FaArrowLeft />
            </div>
            <form onSubmit={handleRegister} className="register-form">
                <input
                    type="text"
                    placeholder="Nome Completo *"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                />
                <input
                    type="email"
                    placeholder="Email *"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Senha *"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Repetir Senha *"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                <input
                    type="text"
                    placeholder="País *"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    list="country-list"
                    required
                />
                <datalist id="country-list">
                    {countries.map((country, index) => (
                        <option key={index} value={country} />
                    ))}
                </datalist>
                <input
                    type="text"
                    placeholder="Estado *"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    list="state-list"
                    required
                />
                <datalist id="state-list">
                    {states.map((state, index) => (
                        <option key={index} value={state} />
                    ))}
                </datalist>
                <input
                    type="text"
                    placeholder="Cidade *"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    list="city-list"
                    required
                />
                <datalist id="city-list">
                    {cities.map((city, index) => (
                        <option key={index} value={city} />
                    ))}
                </datalist>
                <div className="contact-container">
                    <input
                        type="text"
                        placeholder="DDD *"
                        value={ddd}
                        onChange={(e) => setDdd(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Contato *"
                        value={contact}
                        onChange={(e) => setContact(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="register-button">Registrar</button>
            </form>
        </div>
    );
};

export default Register;