import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaUser, FaCog, FaChevronLeft, FaChevronRight, FaPaw, FaHeartbeat, FaDollarSign, FaUsers, FaTint } from 'react-icons/fa';
import './Sidebar.css';

const Sidebar = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setExpandedMenu(null); // Fechar todos os submenus ao recolher a sidebar
    }
  };

  const handleMenuClick = (menu) => {
    if (!isExpanded) {
      setIsExpanded(true); // Expandir a sidebar se estiver minimizada
      setExpandedMenu(menu); // Mostrar o submenu
    } else {
      setExpandedMenu(expandedMenu === menu ? null : menu); // Alternar a visibilidade do submenu
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className={`sidebar ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div className="sidebar-toggle" onClick={toggleSidebar}>
        {isExpanded ? <FaChevronLeft /> : <FaChevronRight />}
      </div>
      <div className="sidebar-content">
        <div className="sidebar-item" onClick={() => navigate('/home')}>
          <FaHome />
          {isExpanded && <span>Página Inicial</span>}
        </div>
        <div className="sidebar-item" onClick={() => handleMenuClick('producaoLeite')}>
          <FaTint />
          {isExpanded && <span>Produção</span>}
        </div>
        {expandedMenu === 'producaoLeite' && isExpanded && (
          <div className="sidebar-submenu">
            <div className="sidebar-item" onClick={() => handleNavigation('/producao-diaria')}>
              <span>Produção Diária</span>
            </div>
            <div className="sidebar-item">
              <span>Média Produção</span>
            </div>
            <div className="sidebar-item">
              <span>Sala</span>
            </div>
          </div>
        )}
        <div className="sidebar-item" onClick={() => handleMenuClick('animais')}>
          <FaPaw />
          {isExpanded && <span>Animais</span>}
        </div>
        {expandedMenu === 'animais' && isExpanded && (
          <div className="sidebar-submenu">
            <div className="sidebar-item">
              <span>Cadastro Animal</span>
            </div>
            <div className="sidebar-item">
              <span>Opção 2</span>
            </div>
            <div className="sidebar-item">
              <span>Opção 3</span>
            </div>
          </div>
        )}
        <div className="sidebar-item" onClick={() => handleMenuClick('saude')}>
          <FaHeartbeat />
          {isExpanded && <span>Saúde</span>}
        </div>
        {expandedMenu === 'saude' && isExpanded && (
          <div className="sidebar-submenu">
            <div className="sidebar-item">
              <span>Opção 1</span>
            </div>
            <div className="sidebar-item">
              <span>Opção 2</span>
            </div>
            <div className="sidebar-item">
              <span>Opção 3</span>
            </div>
          </div>
        )}
        <div className="sidebar-item" onClick={() => handleMenuClick('financeiro')}>
          <FaDollarSign />
          {isExpanded && <span>Financeiro</span>}
        </div>
        {expandedMenu === 'financeiro' && isExpanded && (
          <div className="sidebar-submenu">
            <div className="sidebar-item">
              <span>Opção 1</span>
            </div>
            <div className="sidebar-item">
              <span>Opção 2</span>
            </div>
            <div className="sidebar-item">
              <span>Opção 3</span>
            </div>
          </div>
        )}
        <div className="sidebar-item" onClick={() => handleMenuClick('usuarios')}>
          <FaUsers />
          {isExpanded && <span>Usuários</span>}
        </div>
        {expandedMenu === 'usuarios' && isExpanded && (
          <div className="sidebar-submenu">
            <div className="sidebar-item">
              <span>Opção 1</span>
            </div>
            <div className="sidebar-item">
              <span>Opção 2</span>
            </div>
            <div className="sidebar-item">
              <span>Opção 3</span>
            </div>
          </div>
        )}
        <div className="sidebar-item" onClick={() => handleMenuClick('configuracoes')}>
          <FaCog />
          {isExpanded && <span>Configurações</span>}
        </div>
        {expandedMenu === 'configuracoes' && isExpanded && (
          <div className="sidebar-submenu">
            <div className="sidebar-item">
              <span>Opção 1</span>
            </div>
            <div className="sidebar-item">
              <span>Opção 2</span>
            </div>
            <div className="sidebar-item">
              <span>Opção 3</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;