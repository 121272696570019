import React from 'react';
import './Assinatura.css';

const Assinatura = () => {
    return (
        <div className="assinatura-container">
            <div className="plano premium">
                <h2>Premium</h2>
                <p className="subtitulo">Gerencie seus animais, tenha dados de ordenha em tempo real com equipamentos GMZ.</p>
                <div className="preco">R$ 499,90 /mês</div>
                <div className="detalhes">
                    <p>Gerencie até 1.200 animais em lactação.</p>
                </div>
                <div className="beneficios">
                    <p>✔ Suporte </p>
                    <p>✔ Reprodução</p>
                    <p>✔ Produção (em tempo real)</p>
                    <p>✔ Sanitário</p>
                    <p>✔ Financeiro</p>
                    <p>✔ Saúde Animal</p>
                    <p>✔ Estoque</p>
                    <p>✔ Nutrição</p>
                    <p>✔ Integração Ordenha</p>
                    <p>✔ Monitoramento Animal</p>
                </div>
            </div>
            <div className="plano padrao">
                <h2>Padrão</h2>
                <p className="subtitulo">Gerencie seus animais com o melhor sistema de gerenciamento no mercado.</p>
                <div className="preco">R$ 199,90 /mês</div>
                <div className="detalhes">
                    <p>Gerencie até 5.000 animais.</p>
                </div>
                <div className="beneficios">
                    <p>✔ Suporte </p>
                    <p>✔ Reprodução</p>
                    <p>✔ Produção</p>
                    <p>✔ Sanitário</p>
                    <p>✔ Financeiro</p>
                    <p>✔ Saúde Animal</p>
                    <p>✔ Estoque</p>
                    <p>✔ Nutrição</p>
                    <p>✔ Integração Ordenha</p>
                    <p>✔ Monitoramento Animal</p>
                </div>
            </div>
            <div className="plano anuncios">
                <h2>Básico</h2>
                <p className="subtitulo">Gerencie informações básicas do seus animais necessárias no dia-a-dia.</p>
                <div className="preco">R$ 99,90 /mês</div>
                <div className="detalhes">
                    <p>Gerencie até 500 animais.</p>
                </div>
                <div className="beneficios">
                    <p>✔ Suporte </p>
                    <p>✔ Reprodução</p>
                    <p>✔ Produção</p>
                    <p>✔ Sanitário</p>
                    <p>✔ Financeiro</p>
                    <p>✔ Saúde Animal</p>
                </div>
            </div>
        </div>
    );
};

export default Assinatura;