import React, { useState } from 'react';
import axios from 'axios';
import './ForgotPassword.css';
import logo from '../../assets/logo.png';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:3001/forgot-password', { email });
      alert(response.data);
    } catch (error) {
      console.error('Erro ao enviar email:', error);
      alert('Erro ao enviar email');
    }
  };

  return (
    <div className="forgot-password-container">
      <form onSubmit={handleForgotPassword} className="forgot-password-form">
        <img src={logo} alt="Logo" className="forgot-password-logo" />
        <input
          type="email"
          placeholder="Email *"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="forgot-password-button">Enviar</button>
      </form>
    </div>
  );
};

export default ForgotPassword;
