import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import logo from '../../assets/logo.png';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:3001/login', { login: username, senha: password });
            localStorage.setItem('token', response.data.token);
            navigate(response.data.redirect);
        } catch (error) {
            console.error('Erro ao fazer login:', error);
            alert('Erro ao fazer login');
        }
    };

    const handleRegister = () => {
        navigate('/register');
    };

    const handleForgotPassword = () => {
        navigate('/forgot-password');
    };

    return (
        <div className="login-container">
            <form onSubmit={handleLogin} className="login-form">
                <img src={logo} alt="Logo" className="login-logo" />
                <input
                    type="text"
                    placeholder="E-mail *"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Senha *"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <div className="button-container">
                    <button type="submit" className="login-button">Entrar</button>
                    <button type="button" className="register-button" onClick={handleRegister}>Novo Usuário</button>
                </div>
            </form>
            <a href="#" className="forgot-password" onClick={handleForgotPassword}>Esqueceu sua senha?</a>
        </div>
    );
};

export default Login;
