import React from 'react';
import { FaTint, FaChartBar, FaClock, FaPercentage } from 'react-icons/fa';
import './InfoCard.css';

const icons = {
    'ProducaoDiaria': <FaTint />,
    'MediaVaca': <FaChartBar />,
    'MediaTempo': <FaClock />,
    'Porcentagem2Min': <FaPercentage />,
    'AnimaisAtivos': <FaClock />,
    'AnimaisEmLactacao': <FaPercentage />,
    'Vacas': <FaTint />

};

const InfoCard = ({ title, value, type }) => {
    return (
        <div className={`info-card border-left-${type}`}>
            <div className="info-card-body">
                <div className="info-card-content">
                    <div className="info-card-text">
                        
                        <div className="info-card-value">{value}</div>
                        <div className={`info-card-title text-${type}`}>
                            {title}
                        </div>
                    </div>
                    <div className="info-card-icon">
                        {icons[type]}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoCard;
