import React from 'react';
import { FaBell, FaSignOutAlt } from 'react-icons/fa';
import './Header.css';

const Header = () => {
  return (
    <div className="header">
      <div className="header-right">
        <FaBell className="header-icon" />
        <FaSignOutAlt className="header-icon" />
      </div>
    </div>
  );
};

export default Header;
