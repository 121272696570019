import React from 'react';
import Sidebar from '../../ui/components/Sidebar';
import Header from '../../ui/components/Header';
import InfoCard from '../../components/InfoCard';
import './Home.css';

const Home = () => {
  return (
    <div className='home-wrapper'>
      <Header />
      <div className="home-container">
        <Sidebar />
        <div className="main-content">
          <div className="content">
            <div className="info-boxes">
              <InfoCard title="Produção" value="1389.84 Litros" type="ProducaoDiaria" />
              <InfoCard title="Média Vaca" value="10.65 Litros" type="MediaVaca" />
              <InfoCard title="Animais Ativos" value="100 Vacas" type="AnimaisAtivos" />
              <InfoCard title="Animais em Lactação" value="47 Vacas" type="AnimaisEmLactacao" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
