import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import Assinatura from './pages/assinatura/Assinatura';
import Register from './pages/register/Register';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import ProducaoDiaria from './pages/producao/ProducaoDiaria';
// import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/home" element={<Home />} />
        <Route path="/assinatura" element={<Assinatura />} />
        <Route path="/producao-diaria" element={<ProducaoDiaria />} />
      </Routes>
    </Router>
  );
}

export default App;
